<template>
  <div class="container">
    <div class="main-content">
      <!-- <div class="menu">
        <div class="menu-title">
          <p>{{typetitle}}</p>
        </div>
      </div> -->
      <div class="right-content">
        <div class="header">
          <div class="title">{{title}}</div>
          <div class="label">
            <span class="text">时间: {{time}}</span>
            <span class="text">作者: {{autor}}</span>
          </div>
        </div>
        <div class="content" v-html="content">
        </div>
      </div>

    </div>
  </div>
</template>

<script>

import { article } from '@/api/server';

export default {
  data () {
    return {
      content: '',
      title: '',
      time: '2021-10-19 09:10:23',
      autor: '智慧云测'
    }
  },
  components: {

  },
  mounted () {
    var articleId = this.$route.params.id;
    var that = this;
    console.log("articleId:", articleId);
    article({ id: articleId }).then(res => {
      const { data } = res;
      console.log("data:", data);
      if (data.article_type == "2") { //跳转新地址
        window.open(data.article_url, '_blank');
      } else {

        that.content = that.HTMLEncode(data.article_content);
        that.title = data.article_title;
        that.autor = data.user_name == 'admin' ? '智慧云测' : data.user_name;
        that.time = /\d{4}-\d{1,2}-\d{1,2}/g.exec(data.article_release_date).toString();
      }

      // const regex = new RegExp('<img', 'gi')
      // that.content = content.replace(regex, `<img style="max-width: 100%; height: auto"`)
    })
  },
  methods: {
    HTMLEncode (text) {
      let temp = document.createElement("div");
      temp.innerHTML = text;
      let output = temp.innerText || temp.textContent;
      temp = null;
      return output;
    }
  }
}
</script>

<style scoped lang="scss">
.container {
  width: 100%;
  min-height: 1000px;
  // background-color: #fff;
  // margin-top: 1px;
  padding-bottom: 20px;
  .main-content {
    width: 1200px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
    .menu {
      // position: absolute;
      float: left;
      width: 1200px;
      // height: 200px;
      background: #fff;
      border-bottom: 1px solid #eeeeee;
      line-height: 35px;
      .menu-title {
        background: #409eff;
        padding: 30px 10px 34px 40px;
        text-align: left;
        p {
          font-size: 24px;
          color: #fff;
          margin: 0;
          padding: 0;
        }
      }
    }

    .right-content {
      float: left;
      width: 1200px;
      background: #ffffff;
      // padding: 0 14px;
      position: relative;
      margin-top: 15px;
      .header {
        width: 100%;
        padding: 50px 0;
        .title {
          width: 100%;
          text-align: center;
          padding-bottom: 7px;
          color: #3a3a3a;
          font-size: 28px;
        }
        .label {
          padding-top: 20px;
          .text {
            padding-right: 10px;
            font-size: 13px;
            color: #a2a2a2;
            text-align: center;
            line-height: 32px;
          }
        }
      }
      .content {
        padding: 0 30px;
        min-height: 800px;
      }
    }
  }
}
</style>
<style>
p {
  color: #333;
  text-align: left;
  font-family: 'Open Sans';
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.2px;
  line-height: 26px;
  margin: 0 0 20px;
  word-wrap: break-word;
}
p > img {
  display: inline-block;
  max-width: 100% !important;
  height: auto !important; /*防止图片变形*/
}
table {
  width: 95%;
  background: #ccc;
  margin: 10px auto;
  border-collapse: collapse; /*border-collapse:collapse合并内外边距(去除表格单元格默认的2个像素内外边距*/
}
th,
td {
  height: 25px;
  line-height: 25px;
  text-align: center;
  border: 1px solid #ccc;
}
th {
  background: #eee;
  font-weight: normal;
}
tr {
  background: #fff;
}
</style>

